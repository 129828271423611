<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'cards.svg'" />

			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<div class="small-width">
				<select v-model="form.method" class="cashier-form-control" :placeholder="[[$t('deposit.methods')]]">
					<option
						v-for="(method, index) in methods"
						:key="index"
						:value="index"
						:class="{ active: form.method === method }"
					>
						{{ method }}
					</option>
				</select>
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-errors="formErrors['amount']"
				@set-button-value="setButtonValue"
			/>
			<span class="amount-error">{{ formErrors.amount }}</span>
			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" @click="payWithDirecta()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositCreditCard',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				method: 'MC',
				promocode: null,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			methods: {
				AE: 'American Express',
				VI: 'Visa Card',
				VD: 'Visa Debit',
				MC: 'Mastercard',
				MD: 'Mastercard Debit',
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		currentMethod() {
			return this.$store.getters.getCurrentMethod('cards');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async createTransaction(amount, currency, method, promocode) {
			const response = await apiClient.directaCreateTransaction(
				this.walletId,
				method,
				amount,
				currency,
				promocode,
				'cards'
			);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async payWithDirecta() {
			this.loading = true;

			try {
				const currency = 'USD';
				const transaction = await this.createTransaction(
					this.form.amount,
					currency,
					this.form.method,
					this.form.promocode
				);

				Analytics.trackEvent({
					event: 'Deposit',
					value: this.form.amount,
					label: 'DirectaCreditCard',
				});

				window.location.replace(transaction.link);
			} catch (e) {
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}
				const walletId = this.$route.params.walletId;

				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
